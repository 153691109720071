<template>
    <div class="main-container">
        <h1 class="dark-text fs-18 fw-700" style="margin:25px 0 10px;">Upload<span style="color: #6a1b9a!important;"> /
                Study Material</span></h1>
        <div>
            <div class="row">
                <CourseSubjectTopicChapterSearchVue :showAddModalFromParent="showAddModal" :isFileUpload="true" :showBatchDropDown="true" :isSearchButtonClicked="searchClicked" :isUploadButtonClicked="uploadClicked"
                    @propsChanged="getChangedObject($event)" @modalClosed="modalClosedEvent()" @searchValidations="searchValidationsCompleted($event)" @videoUploadEvent="onVideoUploadEvent($event)">
                </CourseSubjectTopicChapterSearchVue>
                <div class="col l4 m6 s12 p5">
                    <select name="" id="select-input1" class="browser-default pointer" @change="onFileTypeChange($event)"
                        v-model="fileType">
                        <option value=0>All</option>
                        <option :value="fileType.FileUploadFileTypeId" v-for="fileType in fileTypeList"
                            :key="fileType.FileUploadFileTypeId">
                            {{ fileType.FileType }}
                        </option>
                    </select>
                    <div v-if="subjectValidation" style="color: red">Please Select Subject</div>
                </div>
            </div>
            <div class="row">
                <div class="col l2 s12 m2">
                    <button class="add-btn w-100"
                        style="background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);"
                        @click="startSearch()">Search Material</button>
                </div>
                <div class="col l2 s12 m2">
                    <button class="add-btn w-100"
                        style="background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);"
                        @click="showAddModalActions()">Upload File</button>
                </div>
            </div>
            <div class="scroll-verticle-bar scroll-bar" v-if="studyMaterialList.length > 0">
            <div class="practice-details row s12 m12" v-for="data in studyMaterialList" :key="data.fileUploadId">
                <div class="m-0">
                    <div class="title-row flex flex-between items-center display-block">
                        <div class=" fs-16 fw-600 truncate" :title="data.fileName">{{ data.fileName }}</div>
                    </div>
                </div>
                <div class="row flex m-0 display-block">
                    <div class="col s12 m12 l10 padding-lr-20 m-0 border-right row">
                        <!-- <div class="row mb-15 margin-bottom-0"> -->
                            <div class="col m4 s12 text-div p-0 margin-bottom-10">
                                <img :src="require(`../assets/testHistory-books.svg`)" class="icon-bullet">
                                <span class="fw-500 fs-14 pl-5">{{ data.subjectName !== undefined ?
                                    formatString(data.subjectName) : null }}</span>
                            </div>
                            <div class="col m4 s12 text-div p-0 truncate margin-bottom-10" :title="data.Topic">
                                <img :src="require(`../assets/testHistory-openBook.svg`)" class="icon-bullet">
                                <span class="fw-500 fs-14 pl-5">{{ data.topicName !== undefined ?
                                    formatString(data.topicName) : null }}</span>
                            </div>
                            <div class="col m4 s12 text-div p-0 truncate margin-bottom-10" :title="data.chapterName">
                                <img :src="require(`../assets/testHistory-bookmark.svg`)" class="icon-bullet">
                                <span class="fw-500 fs-14 pl-5">{{ data.chapterName !== undefined ?
                                    formatString(data.chapterName) : null }}</span>
                            </div>
                        <!-- </div> -->
                    </div>
                    <div class="col s12 m12 l2 p-0 flex flex-around">
                        <!-- <div class="flex flex-end items-center action-icons"> -->
                            <p class="flex fs-14 fw-600 light-gray-text tooltip pointer flex items-center"
                                style="height: 23px;margin-top: 0px !important"
                                @click="downloadFile(data.fileUploadId)">
                                <img :src="require(`../assets/download.svg`)" alt="" style="width:30px">
                                <span class="tooltiptext">Download</span>
                            </p>
                            <p class="tooltip flex items-center" data-tooltip="I am a tooltip" style="height: 23px;margin-top: 0px !important"
                                @click="openDeleteModal(data.fileUploadId)">
                                <img :src="require(`../assets/delete-new-icon.svg`)" alt="" style="width:30px">
                                <span class="tooltiptext">View</span>
                        </p>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center error-box">
            <img :src="require(`../assets/no-result-found.png`)" alt="" style="width: 200px; opacity: 0.7;">
            <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585;">No material available.</p>
        </div>
        <div v-if="studyMaterialList.length > 0" class="pagination flex flex-between d-block">
            <div>
                <span class="show-index fw-500 fs-15  ws-nowrap ">
                    <span>Showing </span>
                    <span>{{ studyMaterialList.length }}</span>
                    <span> Study Material</span>
                </span>
            </div>
        </div>
        </div>
        <Modal :show="showDeleteModal" :showHeader="false" @close="showDeleteModal = false">
    <template v-slot:body>
      <div class="warning-section-modal">
        <div class="flex flex-center" style="margin-bottom: 20px;padding-top:30px">
          <img class="" :src="require(`../assets/errorsvg.svg`)" style="width:120px;" />
        </div>
        <div class="flex flex-center" style="flex-direction: column">
          <span class=" mesage-body fs-14 fw-500 dark-text" style="white-space: pre-line;">
            Are you sure you want delete this file?
          </span>
        </div>
        <div class="flex content-even" style="padding:20px">
          <button class="confirm-btn fs-13 fw-600 ln-18"
            @click="delFileUpload()">YES</button>
          <button class="cancel-btn fs-13 fw-600 ln-18" @click="showDeleteModal = false">NO</button>
        </div>
      </div>
    </template>
  </Modal>
    </div>
</template>
<script>
import { useToast } from "vue-toastification";
// import Vue from "vue";
import MobileAPI from "../Mobileapi";
// import API from "../Api";
import CourseSubjectTopicChapterSearchVue from "../components/CourseSubjectTopicChapterSearch.vue";
// import Modal3 from "../components/Modal3.vue";
import Modal from "../components/Modal.vue";

const toast = useToast();

export default {
  components: { CourseSubjectTopicChapterSearchVue, Modal },

  data() {
    return {
      showMsg: false,
      showDeleteModal: false,
      showAddModal: false,
      isDateRangeOpen: false,
      showmessage: true,
      uploadfile: false,
      coursedata: [],
      batchData: [],
      subjectData: [],
      chapter: [],
      topic: [],
      topicData: {},
      affiliationCourse: 0,
      affiliationSubject: 0,
      affiliationTopic: 0,
      affiliationChapter: 0,
      affiliationBatch: null,
      affiliationTitle: null,
      courseValidation: false,
      subjectValidation: false,
      topicvalidation: false,
      chapterValidation: false,
      batchValidation: false,
      documentValidation: false,
      courseId: "",
      subjectId: "",
      topicId: "",
      chapterId: "",
      batchId: "",
      classId: null,
      selectedBatches: [],
      studyMaterialList: [],
      deleteCoachingVideo: [],
      whiteListVideo: [],
      affiliationCourseId: "",
      titleValidation: false,
      fileValidation: false,
      coachingId: null,
      videoId: [],
      selectedProps: {
        courseId: 0, subjectId: 0, topicId: 0, chapterId: 0, courseChapterId: 0, batches: "",
      },
      fileType: 0,
      fileTypeList: [],
      fileUploadId: 0,
      searchClicked: false,
      uploadClicked: false,
    };
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  activated() {
    this.$store.dispatch("showLoader", true);
    MobileAPI.getFileTypes((response) => {
      this.$store.dispatch("showLoader", false);

      this.fileTypeList = response.data;
    });
  },
  methods: {
    getChangedObject(data) {
      this.selectedProps = JSON.parse(data);
      console.log("props", this.selectedProps);
    },
    onFileTypeChange() {
      console.log("here");
    },
    modalClosedEvent() {
      this.showAddModal = false;
    },
    showAddModalActions() {
      this.uploadClicked = true;
    },
    resetValues() {
      this.affiliationCourse = 0;
      this.affiliationSubject = 0;
      this.affiliationTopic = 0;
      this.affiliationChapter = 0;
      this.affiliationBatch = null;
      this.affiliationTitle = null;
      this.studyMaterialList = [];
    },
    searchValidationsCompleted(event) {
      const childData = JSON.parse(event);
      this.showmessage = childData.result;
      this.searchClicked = false;
      this.uploadClicked = false;
      if (childData.isSearch === false) {
        if (!this.showmessage) {
          this.showAddModal = true;
        }
      } else if (!this.showmessage) {
        this.searchStudyMaterial();
      }
    },
    deleteFile(id) {
      this.showDeleteModal = true;
      this.coachingId = id;
    },
    formatString(string) {
      const str = string.split(",");
      if (str.length === 1) {
        if (str[0].length > 20) {
          str[0] = str[0].slice(0, 20);
          return `${str[0]}..`;
        }
        return str[0];
      }
      if (str[0].length > 20) {
        str[0] = str[0].slice(0, 20);
      }
      return `${str[0]}... +${str.length - 1}`;
    },
    startSearch() {
      this.searchClicked = true;
    },
    searchStudyMaterial() {
      const user = this.$store.getters.user;

      const data = {

        AffiliationId: Number(user.AffiliationId),
        CenterIds: this.selectedProps.batches.map((a) => a.Id).join(","),
        SubjectId: this.selectedProps.subjectId,
        TopicId: this.selectedProps.topicId,
        ChapterId: this.selectedProps.chapterId,
        FileTypeId: this.fileType,
        ClassId: null,
        DocumentType: "PDF",
      };
      this.$store.dispatch("showLoader", true);
      MobileAPI.getStudyMaterialDetails(data, (response) => {
        this.$store.dispatch("showLoader", false);
        const tempList = response.data;
        if (tempList != null && tempList.length > 0) {
          this.studyMaterialList = tempList.filter((e) => e.type === "PDF");
        } else {
          this.studyMaterialList = [];
        }
        // console.log(List);
        if (this.studyMaterialList.length === 0) {
          toast.info("No records found for your current selection. Please try a different selection", {
            timeout: 2500,
          });
        }
        // console.log(response.data);
        // this.DetailsReset();
      });
    },
    onVideoUploadEvent(event) {
      this.showAddModal = event;
    },
    downloadFile(File) {
      MobileAPI.downloadFileUpload(File, (response) => {
        this.downloadFileUpload = response.data;
        const url = this.downloadFileUpload.FileReference;
        if (url !== "") {
          // window.location.href = url;
          window.open(url, "_blank");
        }
      });
    },
    openDeleteModal(fileUploadId) {
      this.fileUploadId = fileUploadId;
      this.showDeleteModal = true;
    },
    delFileUpload() {
      this.showDeleteModal = false;
      const data = {
        FileUploadId: this.fileUploadId,
      };
      this.$store.dispatch("showLoader", true);
      MobileAPI.deleteFileUpload(data, (response) => {
        this.$store.dispatch("showLoader", false);
        if (response.responseCode === 200) {
          this.searchStudyMaterial();
          toast.info("Deleted Successfully", {
            timeout: 2500,
          });
        }
      });
    },
  },
};

</script>

<style scoped>
.icon-bullet {
    width: 24px;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import 'https://unpkg.com/vue-multiselect@2.0.2/dist/vue-multiselect.min.css';
@import url('https://cdn.jsdelivr.net/npm/vue3-treeselect@^0.1.1/dist/vue3-treeselect.min.css');

* {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box !important;
}

/* custom css */
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}
@media screen and (max-width: 1120px) {
    .display-block {
        display: block !important;
    }

    .margin-tb-10-1120 {
        margin: 10px 0px !important;
    }
}

.padding-lr-20 {
    padding: 0px 20px !important;
}

.border-right {
    height: max-content;
    border-right: 1px solid #D0D0D0;
}

.text-div {
    display: flex;
    align-items: center;
}
.practice-details {
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
}

.title-row {
    margin-right: 14px;
    padding: 15px 20px;
}
.dark-text {
    color: #333333;
}

.line-margin {
    margin: 7px 0;
}

.gray-text-clr {
    color: #333333;
}

/* main css */
.main-container {
    margin-top: 20px;
    padding: 0 10px;
}

.scroll-bar {
    max-height: calc(100vh - 265px);
}

.test-list {
    box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
    border-radius: 6px;
    padding: 12px 17px;
}

.add-btn {
    font-weight: 600;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 7px 18px;
    cursor: pointer;
}

.scroll-verticle,
.scroll-verticle-bar {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    white-space: pre-wrap !important;
}

.scroll-verticle::-webkit-scrollbar {
    width: 0px;
}

.scroll-verticle-bar::-webkit-scrollbar {
    width: 5px;
}

.scroll-verticle::-webkit-scrollbar-track,
.scroll-verticle-bar::-webkit-scrollbar-track {
    background-color: #ececec;
    border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb,
.scroll-verticle-bar::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

input[type=text] {
    border: 1px solid #E0E4F0 !important;
    background-color: white !important;
    height: 43px;
    border-radius: 4px;
    padding: 2px 10px;
    margin: 0;
    /* border-bottom: none !important; */
}

input[type=text]:not(.browser-default):focus:not([readonly]) {
    /* border-bottom: none !important; */
    box-shadow: none;
}

input[type=text]:not(.browser-default) {
    height: 43px !important;
    /* border-bottom: none !important; */
}

select {
    border: 1px solid #E0E4F0 !important;
    background-color: white !important;
    height: 43px;
    border-radius: 4px;
    font-size: 14px;
    color: #adadad;
}

select:focus {
    outline: none;
}

#select-input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("../assets/Vector4.svg") no-repeat right;
    background-size: 38px 6px;
    height: 43px;
    padding-left: 7px;
}

select option:checked {
    color: #3751FF;
    font-weight: 600;
}

option:disabled {
    color: rgb(170, 170, 170) !important;
    font-weight: 500 !important;
}

select {
    overflow: hidden;
}

select::-webkit-scrollbar {
    width: 0;
}

select option {
    color: #333333 !important;
}

input::-webkit-input-placeholder {
    color: #adadad;
}

input::-moz-placeholder {
    color: #adadad;
}

input::-ms-input-placeholder {
    color: #adadad;
}

input::placeholder {
    color: #adadad;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 27px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    /* -webkit-font-feature-settings: 'liga'; */
    -webkit-font-smoothing: antialiased;
    /* cursor: pointer; */
}

.tooltiptext {
    visibility: hidden;
    background-color: #3fbbb9;
    color: white;
    text-align: center;
    padding: 2px 8px;
    border-radius: 3px;
    z-index: 1;
    white-space: break-spaces;
    text-align: center;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    transition: .3s all ease;
    font-weight: 600;
    position: absolute;
    left: -15px;
    bottom: -32px;
    overflow: visible;
}

.tooltiptext::before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: "";
    background: #3fbbb9;
    left: 42%;
    top: -5px;
    -webkit-backdrop-filter: #3fbbb9;
    backdrop-filter: #3fbbb9;
    transform: rotate(45deg);
    background: #3fbbb9;
    visibility: hidden;
    transition: .1s all ease;
    line-height: 20px;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
    visibility: visible;
}

.pagination {
    padding: 10px 14px;
    align-items: center;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.warning-section-modal {
    width: 400px;
    /* height: 356px; */
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirm-btn {
    width: 84px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #37841C;
    border-radius: 4px;
    background: #FFFFFF;
    color: #37841C;
    cursor: pointer;
    margin-right: 10px;
}

.confirm-btn:hover {
    background: #37841C !important;
    color: #FFFFFF !important;
}

.cancel-btn {
    width: 84px;
    padding-right: 5px;
    padding-left: 5px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #FF7675;
    border-radius: 4px;
    color: #FF7675;
    cursor: pointer;
    margin-left: 10px;
}

.cancel-btn:hover {
    background: #FF7675 !important;
    color: #FFFFFF !important;
}

.concept-modal {
    max-width: 1173px !important;
    /* min-width: 650px !important; */
    width: 100% !important;
}

.publish-head {
    position: relative;
    width: 100%;
    background: #F3F5FC;
    padding: 10px;
    border-radius: 5px 5px 0 0;
}

.pl-5 {
    padding-left: 5px;
}

.p5 {
    padding: 5px;
}

.publish-close {
    background-color: #FF7675 !important;
    border: 1px solid #E0E4F0;
    border-radius: 3px;
}

.text-div {
    display: flex;
    align-items: center;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.modal-content {
    padding: 10px;
}

.mb-0 {
    margin-bottom: 0;
}

.date-column {
    height: 39px !important;
    color: black !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding-left: 12px !important;
    background: #FFFFFF;
    border: 1px solid #E0E4F0 !important;
    border-radius: 4px !important;
    /* width: 310px !important; */
}

.light-gray-text {
    color: #333333;
}

.publish-modal-button {
    background: #37841C;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    margin-right: 30px;
    cursor: pointer;
    padding: 10px 15px;
}

.dark-font {
    color: #333;
}

/* Media Query */
@media screen and (max-width: 800px) {
    .input-box {
        margin-bottom: 10px;
    }

    .test-list {
        display: block;
    }

    .column-3 {
        clear: left;
    }

    .column-3 h2:nth-child(1) {
        margin-top: 7px;
    }

    .pagination {
        margin-bottom: 10px;
    }

    .test-details {
        display: block !important;
    }

}

@media screen and (max-width: 600px) {

    /* .row{
      margin-bottom: 0;
    } */
    .add-btn {
        margin-bottom: 20px;
    }

    .input-box {
        padding-right: 0 !important;
    }

    .warning-section-modal {
        width: 300px;
    }
}</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
